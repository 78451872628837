<template>
  <div id="technology">
    <div class="headerBox">
      <Header now-nav="/product" ref="header" @goAnchor="goAnchor" @goTop="goTop"></Header>
      <div class="mainContent">
        <p>工业路由器、系统软件、巡检机器人</p>
        <h4>产品与服务</h4>
        <div class="contentText">致力于工业物联网生态闭环，具备高兼容和柔性适配能力</div>
        <img :src="baseUrl+'imges/technology/productBG.png'" alt="" ref="cur" class="img_big">
        <img :src="baseUrl+'imges/technology/MobileProductBG.png'" ref="cur1" alt="" class="MobileBG">
      </div>
    </div>
    <!--  内容区  -->
    <div class="contentBox">
      <div class="productBox" id="product_1">
        <p class="productP" data-aos="fade-up" data-aos-duration="1000">硬件产品</p>
        <div class="productBox1">
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Productlist', params: {type:'5G01',name:'RA52-CN工业(5G)路由器'}}">
              <img :src="baseUrl+'imges/technology/RA52-CN.png'" alt="">
            </router-link>
            <span class="spanTitle">RA52-CN工业路由</span>
            <span class="spanText">具有5G技术通信，高性能，高可靠，高稳定性等优点</span>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Productlist', params: {type:'KT100',name:'工业4G路由器'}}">
              <img :src="baseUrl+'imges/technology/RA42-CN(4G).png'" alt="">
            </router-link>
            <span class="spanTitle">工业4G路由器</span>
            <span class="spanText">应用于智能货柜，智慧电力等多个行业场景</span>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Productlist', params: {type:'Z0115',name:'DTU-Z0115'}}">
              <img :src="baseUrl+'imges/technology/DTU-Z0115.png'" alt="">
            </router-link>
            <span class="spanTitle">DTU-Z0115</span>
            <span class="spanText">专门用于将串口数据转换为IP数据的无线终端设备</span>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Productlist', params: {type:'CPE',name:'5G CPE'}}">
              <img :src="baseUrl+'imges/technology/5GCPE.png'" alt="">
            </router-link>
            <span class="spanTitle">5G CPE</span>
            <span class="spanText">拥有高速可靠的网络性能和稳定的AP接入能力</span>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Productlist', params: {type:'light',name:'便捷式应急灯'}}">
              <img :src="baseUrl+'imges/technology/light.png'" alt="">
            </router-link>
            <span class="spanTitle">便捷式应急灯</span>
            <span class="spanText">超硬度阳极点镀锌外观表面，防爆耐用</span>
          </div>
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Productlist', params: {type:'5G02',name:'双模5G路由器'}}">
              <img :src="baseUrl+'imges/technology/双模5G路由器.png'" alt="">
            </router-link>
            <span class="spanTitle">双模5G路由器</span>
            <span class="spanText">通过链路监测与聚合，提供可靠的高速上下行速度</span>
          </div>
        </div>
        <p class="technologyP" data-aos="fade-up" data-aos-duration="1000" id="robot_1">巡检机器人</p>
        <div class="robotBox">
          <div data-aos="fade-up" data-aos-duration="1000">
            <router-link :to="{name: 'Technologylist', params: {type:'personnelLocationSystem',name:'挂轨巡检机器人'}}">
              <img :src="baseUrl+'imges/technology/NewRobot.png'" alt="">
            </router-link>
            <span class="spanTitle">挂轨巡检机器人</span>
            <span class="spanText">智慧管廊可视化平台描述</span>
          </div>
        </div>

      </div>
    </div>
    <Footer @goAnchor="goAnchor" @goTop="goTop"></Footer>

  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "Technology",
  mounted() {
    setTimeout(() =>{
      this.$refs.cur.classList.add('small')
      this.$refs.cur1.classList.add('small')
    }, 400)

    //监听滚动高度
    window.addEventListener("scroll", this.handleScroll, true);
    //获取 硬件产品 节点高度
    this.product_1 = document.getElementById('product_1').offsetTop
    //获取 巡检机器人 节点高度
    this.robot_1 = document.getElementById('robot_1').offsetTop
    if(this.$store.state.ToScrollIntoView === 1){
      this.goAnchor('#product_1')
    } else if(this.$store.state.ToScrollIntoView === 2){
      this.goAnchor('#robot_1')
    } else {
      this.goTop()
    }
  },
  data() {
    return {
      scrollTop: 0,
      product_1: 0,
      robot_1: 0,
      tab1: true,
      tab2: false,
      tab3: false,
      tab4: false,
      tab5:false
    }
  },
  components: {
    Header,
    Footer
  },
  methods: {
    //监听滚动高度的方法
    handleScroll(e) {
      this.scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
    },
    //锚点跳转
    goAnchor(selector) {/*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth"
      })
    },
    //置顶方法
    goTop(){
      document.documentElement.scrollTop = 0
    },
    //鼠标事件
    mouseEnter(e) {
      if (e === "tab1") {
        this.tab1 = true;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
        this.tab5 = false;
      } else if (e === "tab2") {
        this.tab1 = false;
        this.tab2 = true;
        this.tab3 = false;
        this.tab4 = false;
        this.tab5 = false;
      } else if (e === "tab3") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = true;
        this.tab4 = false;
        this.tab5 = false;
      } else if (e === "tab4") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = true;
        this.tab5 = false;
      }else if (e === "tab5") {
        this.tab1 = false;
        this.tab2 = false;
        this.tab3 = false;
        this.tab4 = false;
        this.tab5 = true;
      }
    }
  }
}
</script>

<style lang="less" scoped>
#technology {
  background: #FFFFFF;
  .headerBox{
    position: relative;
    width: 100%;
    height: 1080px;
    .mainContent{
      cursor: default;
      user-select: none;
      overflow: hidden;
      .MobileBG{
        display: none;
      }
      .img_big{
        display: block;
        width: 100%;
        height: 1080px;
        transition: all 1.4s;
        transform: scale(1.06);
      }
      .small{
        transform: scale(1);
      }
      h4{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 436px;
        width: 708px;
        height: 43px;
        font-family: Medium;
        font-size: 50px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: #FFFFFF;
        z-index: 10;
      }
      p{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 868px;
        height: 42px;
        top: 372px;
        font-family: Regular;
        font-size: 20px;
        line-height: 17px;
        letter-spacing: 0em;
        color: #F2F3FE;
        z-index: 10;
      }
      .contentText{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 528px;
        width: 501px;
        height: 53px;
        background: rgba(255, 255, 255, 0.2);
        box-sizing: border-box;
        border: 1px solid rgba(255, 255, 255, 0.4);
        font-family: Regular;
        font-size: 14px;
        line-height: 53px;
        letter-spacing: 0em;
        color: #FFFFFF;
        z-index: 10;
      }
    }
  }
  .contentBox {
    .productBox{
      position: relative;
      top: 74px;
      width: 1250px;
      height: 1650px;
      //height: 2458px;
      margin: 0 auto;
      .productP{
        position: absolute;
        top: 0;
        left: 0;
        width: 215px;
        height: 42px;
        font-size: 30px;
        font-family: Medium;
        //font-weight: bold;
        color: #333333;
        text-align: left;
      }
      .productBox1{
        position: relative;
        top: 108px;
        left: 0;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        width: 100%;
        height: 860px;
        div{
          margin-right: 42px;
          a{
            display: block;
            width: 369px;
            height: 299px;
            //border: 1px dashed #a0a0a0;
            img{
              width: 369px;
              height: 299px;
              transition: all .6s;
              overflow: hidden;
            }
            img:hover{
              transform: scale(1.1);
            }
          }
          .spanTitle{
            display: block;
            text-align: left;
            width: 186px;
            height: 25px;
            margin-top: 20px;
            font-size: 18px;
            font-family: Medium;
            font-weight: bold;
            color: #333333;
          }
          .spanText{
            display: block;
            margin-top: 14px;
            width: 342px;
            height: 20px;
            font-size: 14px;
            text-align: left;
            font-family: Regular;
            font-weight: 400;
            color: #999999;
          }
        }
        .LeftButton{
          position: absolute;
          left: -118px;
          top: 280px;
          //cursor: pointer;
          cursor: not-allowed;
        }
        .RightButton{
          position: absolute;
          right: -118px;
          top: 280px;
          //cursor: pointer;
          cursor: not-allowed;
        }
      }
      .LeftButton{
        position: absolute;
        left: -118px;
        top: 280px;
        cursor: pointer;
      }
      .RightButton{
        position: absolute;
        right: -118px;
        top: 280px;
        cursor: pointer;
      }
      .technologyP{
        position: absolute;
        top: 1010px;
        left: 0;
        width: 215px;
        height: 42px;
        font-size: 30px;
        font-family: Medium;
        //font-weight: bold;
        color: #333333;
        text-align: left;
      }
      .robotBox{
        position: relative;
        top: 258px;
        //top: 328px;
        left: 0;
        display: flex;
        justify-content: left;
        flex-wrap: wrap;
        width: 100%;
        height: 370px;
        div{
          margin-right: 42px;
          //a{
          display: block;
          width: 369px;
          height: 299px;
          //border: 1px dashed #a0a0a0;
          img{
            width: 369px;
            height: 299px;
            transition: all .6s;
            overflow: hidden;
          }
          img:hover{
            transform: scale(1.1);
          }
          //}
          .spanTitle{
            display: block;
            text-align: left;
            width: 186px;
            height: 25px;
            margin-top: 26px;
            font-size: 18px;
            font-family: Medium;
            font-weight: bold;
            color: #333333;
          }
          .spanText{
            display: block;
            margin-top: 14px;
            width: 342px;
            height: 20px;
            font-size: 14px;
            text-align: left;
            font-family: Regular;
            font-weight: 400;
            color: #999999;
          }
        }

      }
    }

  }
}

//媒体查询适应手机端
@media screen and (min-width: 360px) and (max-width: 750px) {
  html {
    font-size: 140px / 10;
  }
  #technology{
    background: #FFFFFF;
    height: 100%;
    .headerBox {
      position: relative;
      width: 100%;
      height: 100%;
      .mainContent{
        cursor: default;
        user-select: none;
        overflow: hidden;
        .MobileBG{
          width: 100%;
          height: 589px;
          display: block;
          transition: all 1.4s;
          transform: scale(1.06);
        }
        .img_big{
          display: none;
          width: 100%;
          height: 1080px;
          transition: all 1.4s;
          transform: scale(1.06);
        }
        .small{
          transform: scale(1);
        }
        h4{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 203px;
          font-family: Medium;
          width: 180px;
          height: 36px;
          font-size: 30px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: #FFFFFF;
          z-index: 1;
        }
        p{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 169px;
          font-family: Regular;
          width: 231px;
          height: 17px;
          font-size: 14px;
          font-weight: normal;
          line-height: 17px;
          letter-spacing: 0em;
          color: #F2F3FE;
          z-index: 0
        }
        .contentText{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 266px;
          background: rgba(255, 255, 255, 0);
          box-sizing: border-box;
          border: 1px solid rgba(255, 255, 255, 0);
          font-family: Regular;
          width: 167px;
          height: 36px;
          font-size: 12px;
          font-weight: normal;
          line-height: 18px;
          letter-spacing: 0em;
          color: #FFFFFF;
          z-index: 1
        }
      }

    }

    .contentBox{
      height: 100%;
      .productBox{
        position: relative;
        width: 94%;
        height: 100%;
        margin: 16px auto 132px;
        .productP{
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 0;
          text-align: center;
          width: 100%;
          height: 29px;
          font-family: Regular;
          font-size: 20px;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: #454545;
        }
        .productP:after{
          /* 必须设置 content 属性才能生效 */
          content: '';
          /* border 宽度 */
          width: 31px;
          /* border 高度 */
          height: 2px;
          background-color: #0564AF;
          /* border 位置 absolute(绝对定位) */
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 35px;
          /* 自动内减 */
          box-sizing: border-box;
        }
        .productBox1{
          width: 100%;
          height: 786px;
          position: relative;
          top: 71px;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          //align-content: space-between;
          div{
            width: 49%;
            height: 253px;
            background: #F5F5F5;
            margin-right: 0;
            overflow: hidden;
            a{
              display: block;
              width: 181px;
              height: 147px;
              margin: 15px auto 7px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .spanTitle{
              text-align: center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 169px;
              width: 165px;
              height: 26px;
              font-family: Medium;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0em;
              color: #454545;
            }
            .spanText{
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 195px;
              width: 150px;
              height: 38px;
              text-align: center;
              font-family: Regular;
              font-size: 10px;
              font-weight: normal;
              letter-spacing: 0em;
              color: #999999;
            }
          }
        }
        .technologyP{
          position: relative;
          left: 50%;
          transform: translateX(-50%);
          top: 0px;
          margin-top: 100px;
          text-align: center;
          width: 100%;
          height: 29px;
          font-family: Regular;
          font-size: 20px;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: #454545;
        }
        .technologyP:after{
          /* 必须设置 content 属性才能生效 */
          content: '';
          /* border 宽度 */
          width: 31px;
          /* border 高度 */
          height: 2px;
          background-color: #0564AF;
          /* border 位置 absolute(绝对定位) */
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 35px;
          /* 自动内减 */
          box-sizing: border-box;
        }
        .robotBox{
          position: relative;
          width: 100%;
          height: 100%;
          div{
            width: 49%;
            height: 253px;
            background: #F5F5F5;
            margin-right: 0;
            overflow: hidden;
            a{
              display: block;
              width: 181px;
              height: 147px;
              margin: 15px auto 7px;
              img{
                width: 100%;
                height: 100%;
              }
            }
            .spanTitle{
              text-align: center;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 169px;
              width: 165px;
              height: 26px;
              font-family: Medium;
              font-size: 14px;
              font-weight: 500;
              letter-spacing: 0em;
              color: #454545;
            }
            .spanText{
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 195px;
              width: 150px;
              height: 38px;
              text-align: center;
              font-family: Regular;
              font-size: 10px;
              font-weight: normal;
              letter-spacing: 0em;
              color: #999999;
            }
          }

        }
      }
    }
  }
}
</style>

